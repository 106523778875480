<template>
    <b-form>
      <div class="row">
        <div v-if="modalEvent.id" class="col-md-12 text-right">

          <b-button v-if="!modalEvent.isCancelled" size="xs" variant="warning" @click="toggleCancelledStatus">Ορισμός ως ακυρωμένο</b-button>
          <b-button v-else size="xs" variant="success" @click="toggleCancelledStatus">Ορισμός ως μη-ακυρωμένο</b-button>

        </div>
        <div class="col-md-12 mt-2">
          <b-form-radio-group id="event_type" v-model="modalEvent.type" label="Είδος">
            <b-form-radio value="1">Ραντεβού με πελάτη</b-form-radio>
            <b-form-radio value="0">Γεγονός / Δραστηριότητα</b-form-radio>
          </b-form-radio-group>


          <b-form-input v-if="parseInt(modalEvent.type) === 0" v-model="modalEvent.title" class="mt-2" placeholder="Τίτλος συμβάντος"></b-form-input>
        </div>
        <div class="col-md-6 mt-2">
          <b-form-group id="birthDate" label="Ημερομηνία" label-for="birthDate">
            <date-picker v-model="modalEvent.date"  hide-clear-button :first-day-of-week="1" :lang="elDatePicker" format="DD-MM-YYYY"></date-picker>
          </b-form-group>
        </div>
        <div class="col-md-6 mt-2">
          <label>Ώρα έναρξης:</label>
          <b-form-input v-model="modalEvent.startTime" @input="val => formatTime(val, true)" maxlength="5" @blur="fixFormat(true)"></b-form-input>
        </div>

        <div class="col-md-6 mt-2">
          <label>Διάρκεια: </label>
          <b-form-select id="select-duration" v-model="modalEvent.duration" class="custom-select" @change="updateModalEventEndTime">
            <b-form-select-option value='15'>15 λεπτά</b-form-select-option>
            <b-form-select-option value='20'>20 λεπτά</b-form-select-option>
            <b-form-select-option value='30'>30 λεπτά</b-form-select-option>
            <b-form-select-option value='45'>45 λεπτά</b-form-select-option>
            <b-form-select-option value='60'>60 λεπτά</b-form-select-option>
          </b-form-select>
        </div>

        <div class="col-md-6 mt-2">
          <label>Ώρα λήξης:</label>
          <b-form-input v-model="modalEvent.endTime" @input="val => formatTime(val, false)" maxlength="5" @blur="fixFormat(false)"></b-form-input>
        </div>
        <div class="col-md-12 mt-3">
          <label>Σημειώσεις</label>
          <b-form-textarea v-model="modalEvent.notes" placeholder="Σημειώσεις"></b-form-textarea>
        </div>

        <div v-if="parseInt(modalEvent.type) === 1" class="col-md-6 mt-3">
          <label>Επιλογή πελάτη:</label>
          <v-select
              v-model="modalEvent.selectedAthlete"
              placeholder="Επιλογή πελάτη..."
              :options = "athletes"
              label="item_data"
              :reduce="athlete => athlete.id"
              @input="updateSessionCost">
          </v-select>
          <b-button variant="link" style="margin:0;padding:0" class="mt-2" @click="createNewClient = !createNewClient" >Δημιουργία νέου πελάτη</b-button>
        </div>
        <div v-if="parseInt(modalEvent.type) === 1" class="col-md-6 mt-3">
          <label>Κόστος συνεδρίας:</label>
          <b-input-group append="€" >
            <b-form-input type="number" step="1" v-model="modalEvent.sessionCost" placeholder="(π.χ. 50)"/>
          </b-input-group>
        </div>

        <div v-if="createNewClient" class="col-md-12 border-1 p-1">

          <div class="row">
            <div class="col-md-6">
              <b-form-group id="input-firstName" label="Όνομα*" label-for="firstName" >
                <b-form-input id="firstName" v-model="newClient.firstName" type="text" placeholder="Εισάγετε το όνομα του πελάτη" required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group id="input-lastName" label="Επώνυμο*" label-for="lastName">
                <b-form-input id="lastName" v-model="newClient.lastName" type="text" placeholder="Εισάγετε το επώνυμο του πελάτη" required></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <b-form-group id="input-birthDate" label="Ημερομηνία Γέννησης*" label-for="birthDate">
                <date-picker v-model="newClient.birthDate"  :first-day-of-week="1" :lang="elDatePicker" format="DD-MM-YYYY" placeholder="Ημέρα/μήνας/έτος"></date-picker>
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group id="input-gender" label="Φύλο*" label-for="gender">
                <b-form-select v-model="newClient.sex" required>
                  <option value="null">Επιλέξτε φύλο: </option>
                  <option value="M">Άρρεν</option>
                  <option value="F">Θήλυ</option>
                </b-form-select>
              </b-form-group>
            </div>

          </div>
          <div class="row">
            <div class="col-md-6">
              <b-form-group id="input-email" label="E-mail" label-for="email" >
                <b-form-input id="email" v-model="newClient.email" type="text"
                              @change="newClient.email = newClient.email.replaceAll(' ','').toLowerCase()"
                              placeholder="Προαιρετικό πεδίο"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group id="input-mobileNum" label="Κινητό τηλέφωνο" label-for="mobileNum">
                <b-form-input id="mobileNum" v-model="newClient.mobileNum" type="text" placeholder="Προαιρετικό πεδίο"></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-12 mb-2">
              <label>Κόστος συνεδρίας:</label>
              <b-input-group append="€" >
                <b-form-input type="number" step="1" v-model="newClient.sessionCost" placeholder="(π.χ. 50)"/>
              </b-input-group>
            </div>

          </div>
          <div class="text-right">
            <b-button size="xs" variant="success" @click="saveNewCustomer" >Αποθήκευση πελάτη</b-button>
          </div>

        </div>

        <div class="col-md-12 mt-2">
          <b-alert show v-if="hasError" variant="danger">test: {{modalEvent.error}}</b-alert>
        </div>
        <div class="col-md-12 mt-2 text-right">
          <b-button v-if="!modalEvent.id" variant="primary" @click="createEvent(false)" :disabled="modalEvent.isSubmitting"><i class="fe-plus"></i> Προσθήκη ραντεβού</b-button>
          <b-button v-if="modalEvent.id" size="xs" variant="danger"
                    @click="deleteEvent" class="mr-2" :disabled="modalEvent.isSubmitting"><i class="fe-save"></i> Διαγραφή ραντεβού</b-button>
          <b-button v-if="modalEvent.id" size="sm" variant="success" @click="createEvent(true)" :disabled="modalEvent.isSubmitting"><i class="fe-save"></i> Ενημέρωση ραντεβού</b-button>
        </div>
      </div>


    </b-form>
</template>

<script>
import moment from "moment";
import DatePicker from 'vue2-datepicker'
import VueTimepicker from 'vue2-timepicker'

export default{
  props:{
    modalEvent: {
      type: Object,
      required: true
    },
    athletes: {
      type: Array,
      required: true
    }
  },
  components:{DatePicker, VueTimepicker},
  data(){
    return{
      createNewClient: false,
      newClient: null,
      hasError: false
    }
  },
  created(){
    this.newClient = this.getBaseAthlete
  },
  computed:{
    getBaseAthlete(){
      return {
        firstName: '',
        lastName: '',
        sex: null,
        birthDate: '',
        email: '',
        mobileNum: '',
        sessionCost: 0,
        athleteShortQuestionnaire: {
          q1: new Date(), q2: null, q3: null, q4: null, q5: null, q6: null, q7: null, q8 :null, q9: null, q10     : null, q11     : null, q12     : null, q13     : null, q13_1     : null,
          q14     : null, q14_1     : null, q15     : null, q15_1     : null, q16     : null, q16_1     : null, q17     : null, q17_1     : null, q18     : null, q18_1     : null, q19     : null, q19_1     : null, q20     : null, q20_1     : null,
          q21     : null, q21_1     : null, q22     : null, q22_1     : null, q23     : null, q23_1     : null, q24     : null, q24_1     : null, q25     : null, q25_1     : null, q26     : null, q26_1     : null,
          q27     : null, q28    : null, q29     : null, q30     : null, q31     : null, q32     : null, q33     : null, q34     : null, q35     : null,},
        measurements: {
          weight: null,
          height: null,
          bmi: null,
          fatPercentage: null
        },
      }
    }
  },

  methods:{
    updateModalEventEndTime(){
      this.modalEvent.endTime = moment().set("hour", this.modalEvent.startTime.substring(0,2)).set("minute", this.modalEvent.startTime.substring(3,5)).add(this.modalEvent.duration, 'minutes').format('HH:mm');
    },
    updateSessionCost(){
      if(this.modalEvent.selectedAthlete){
        let athlete = this.athletes.find(x => x.id === this.modalEvent.selectedAthlete)
        if(athlete.sessionCost && athlete.sessionCost > 0) this.modalEvent.sessionCost = athlete.sessionCost;
      }
    },
    createEvent(isUpdate = false){

      this.modalEvent.error = '';
      let type = parseInt(this.modalEvent.type);
      let title, athlete;

      if(type === 1){
        if(!this.modalEvent.selectedAthlete){
          this.modalEvent.error = 'Παρακαλώ επιλέξτε έναν πελάτη ή αλλάξτε τον τύπο του συμβάντος.';
          this.hasError = true;
          return;
        }
        athlete = this.athletes.find( x => {return x.id === this.modalEvent.selectedAthlete});
        if(!athlete){
          this.modalEvent.error = 'Παρακαλώ επιλέξτε έναν πελάτη ή αλλάξτε τον τύπο του συμβάντος.';
          this.hasError = true;
          return;
        }
        title =  athlete.firstName + ' ' + athlete.lastName;
      }

      let dbObject = {
        type: this.modalEvent.type,
        title: type === 1 ? title : (this.modalEvent.title || 'Συμβάν χωρίς τίτλο'),
        date: moment(this.modalEvent.date).format('YYYY-MM-DD'),
        notes: this.modalEvent.notes,
        startTime: this.modalEvent.startTime,
        endTime: this.modalEvent.endTime,
        athleteId: type === 1 ? athlete.id : null,
        sessionCost: this.modalEvent.sessionCost,
        duration: this.modalEvent.duration
      }

      this.modalEvent.isSubmitting = true;
      if(!isUpdate){
        this.$axios.post(`/calendar`, dbObject).then(res => {
          res.isUpdate = false;
          this.$emit('eventUpdated', res);
        }).catch(e => {
          this.modalEvent.isSubmitting = false;
          this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: 'Κάτι πήγε λάθος!',
            text: 'Η δημιουργία του συμβάντος απέτυχε. Παρακαλώ δοκιμάστε ξανά.'});
        });

      }else{
        this.$axios.put(`/calendar/${this.modalEvent.id}`, dbObject).then(res => {
          res.isUpdate = true;
          this.$emit('eventUpdated', res);
        }).catch(e => {
          this.modalEvent.isSubmitting = false;
          this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: 'Κάτι πήγε λάθος!',
            text: 'Η ενημέρωση του συμβάντος απέτυχε. Παρακαλώ δοκιμάστε ξανά.'});
        });
      }


    },
    deleteEvent(){
      this.modalEvent.isSubmitting = true;
      this.$axios.delete(`/calendar/${this.modalEvent.id}`).then(res => {
        this.$emit('eventDeleted', this.modalEvent.id);
      }).catch(e =>{
        this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: 'Κάτι πήγε λάθος!',
          text: 'Η διαγραφή του συμβάντος απέτυχε. Παρακαλώ δοκιμάστε ξανά.'});
      }).finally(()=>{
        this.modalEvent.isSubmitting = false;
      });
    },
    toggleCancelledStatus(){
      this.$axios.put(`/calendar/${this.modalEvent.id}/status`, {isCancelled: !this.modalEvent.isCancelled}).then(res => {
        this.$emit('cancelledStatusToggled', res);
      }).catch(e => {
        this.modalEvent.isSubmitting = false;
        this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: 'Κάτι πήγε λάθος!',
          text: 'Η ενημέρωση του συμβάντος απέτυχε. Παρακαλώ δοκιμάστε ξανά.'});
      });
    },

    formatTime(val, isStart){
      if(val.length < 2 && val > 2) this.modalEvent[isStart ? 'startTime' : 'endTime'] = '0' + val + ':';
      // we convert [24...29] to 23
      if(val.length === 2 && val > 23) this.modalEvent[isStart ? 'startTime' : 'endTime'] = '23';
      // we add the separator
      if(val.length === 2 && !val.includes(':')) this.modalEvent[isStart ? 'startTime' : 'endTime'] = val + ':';

      if(isStart && this.modalEvent['startTime'].length === 5) this.updateModalEventEndTime();
    },

    fixFormat(isStart){
      // add validity check!
      let model = isStart ? 'startTime' : 'endTime';
      if(this.modalEvent[model].length === 4) this.modalEvent[model] = this.modalEvent[model] + '0';
      if(isStart) this.updateModalEventEndTime();
    },
    saveNewCustomer(){
      this.modalEvent.error = null;
      if(!this.newClient.firstName || !this.newClient.lastName || !this.newClient.sex || !this.newClient.birthDate){
        this.modalEvent.error = 'Παρακαλώ εισάγετε όλα τα υποχρεωτικά στοιχεία για να δημιουργήσετε τον πελάτη.'
        return;
      }

      this.newClient.birthDate = new Date(this.newClient.birthDate).setHours(12); // fix date issue!

      this.$axios.post(`/athlete/`, this.newClient).then(result=>{
        let athlete = result.data.athlete;
        athlete.item_data = athlete.firstName + ' ' + athlete.lastName;
        this.athletes.push(athlete);
        this.modalEvent.selectedAthlete = athlete.id;
        if(athlete.sessionCost && athlete.sessionCost > 0) this.modalEvent.sessionCost = athlete.sessionCost;
        this.createNewClient = false;
        this.newClient = this.getBaseAthlete
        this.$notify({group: 'athlisis-notifications', type:'success',title: 'Επιτυχία!', text: 'Ο πελάτης δημιουργήθηκε επιτυχώς!'});
      }).catch( e => {
        this.isCreatingAthlete = false;
        this.$notify({group: 'athlisis-notifications', type:'error',title: 'Αποτυχία!', text: 'Η δημιουργία του πελάτη απέτυχε!!'});
      });


    },
  }
}
</script>
